import { useActiveUser } from '~/composables/auth';
import { useActiveOrg } from '~/composables/orgs';
import {
	isOrgAdmin,
	isOrgOwner,
	isOrgReadOnly,
	type OrgMemberRole,
	orgMemberRole,
	orgMemberRolePositions,
} from '~/backend/utils/PermissionGuard';

export function usePermissionGuard() {
	const activeUser = useActiveUser();
	const activeOrg = useActiveOrg();

	const userOrgMember = computed(() => activeUser.value.permissions.find((permission) => permission.orgId === activeOrg.value.id));

	function isPermissionLower(targetOrgMemberRole: OrgMemberRole) {
		const userOrgMemberRole = userOrgMember.value?.role ?? orgMemberRole.readOnly;

		const orgMemberRolePositionA = orgMemberRolePositions.findIndex((role: OrgMemberRole) => role === userOrgMemberRole);
		const orgMemberRolePositionB = orgMemberRolePositions.findIndex((role: OrgMemberRole) => role === targetOrgMemberRole);
		return orgMemberRolePositionA < orgMemberRolePositionB;
	}

	const iAmReadOnly = computed(() => {
		return userOrgMember.value && isOrgReadOnly(userOrgMember.value.role);
	});

	const iAmAdmin = computed(() => {
		return userOrgMember.value && isOrgAdmin(userOrgMember.value.role);
	});

	const iAmOwner = computed(() => {
		return userOrgMember.value && isOrgOwner(userOrgMember.value.role);
	});

	return { isPermissionLower, iAmReadOnly, isReadOnly: iAmReadOnly, iAmAdmin, isAdmin: iAmAdmin, iAmOwner, isOwner: iAmOwner };
}
